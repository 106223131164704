<script setup lang="ts">
import type { SoloCTA } from '../../utils'

const props = defineProps<SoloCTA>()
const link = computed(() => props.cta[props.cta.linkType])
const emit = defineEmits(['mounted', 'updated'])
onMounted(() => emit('mounted'))
onUpdated(() => emit('updated'))
</script>

<template>
  <div :class="['solo-cta pb-8', themeStyles(theme ?? 'dark')]">
    <div
      :class="[
        'max-wrapper flex',
        { readable },
        {'justify-start': position === 'left'},
        {'justify-center': position === 'center'},
        {'justify-end': position === 'right'}
      ]"
    >
      <BlockContent v-if="ctaContent" :content="ctaContent" />
      <POButton
        v-if="props.cta.linkType"
        :id="props.cta.gaTag"
        class="mt-2"
        :btn-style="cta.style"
        :color="cta.color"
        :uppercase="true"
        :link="link"
      >
        {{ props.cta.text }}
      </POButton>
    </div>
  </div>
</template>
